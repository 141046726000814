@import '@/styles/mixins';

.nav {
	position: fixed;
	top: 50%;
	right: 4rem;

	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	gap: 1.1rem;

	transform: translateY(-50%);
	list-style: none;

	@include sm {
		display: none;
	}
}

.link {
	width: 1.1rem;
	height: 1.1rem;

	border-radius: 50%;

	transition: all 0.2s ease;
}

.light {
	.link {
		background-color: #ffffff;
		opacity: 0.3;

		&.active {
			opacity: 1;
		}
	}
}

.dark {
	.link {
		background-color: #14489d;
		opacity: 0.1;

		&.active {
			opacity: 1;
		}
	}
}
