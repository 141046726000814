@import 'src/styles/mixins';

.contactUsForm {
	position: relative;
	background: var(--accent-color);
	padding-top: 7rem;
	padding-bottom: 12.5rem;

	color: white;

	@include sm {
		padding: 6.5rem 0;
	}

	svg {
		position: absolute;

		@include no-select();
	}

	&Inner {
		display: flex;
		justify-content: space-between;

		@include sm {
			flex-direction: column;
		}
	}

	.sectionTitle {
		margin-bottom: 3.4rem;

		font-size: 4rem;
		font-weight: 700;
		line-height: 110%;

		@include sm {
			margin-bottom: 3rem;

			font-size: 2.7rem;
			font-weight: 700;
		}
	}

	.sectionSubtitle {
		margin-bottom: 0.5rem;
		color: rgba(255, 255, 255, 0.7);
	}

	.tel {
		font-size: 2.4rem;
		font-weight: 500;
		line-height: 110%;
		letter-spacing: 0.01em;

		@include sm {
			font-size: 2rem;
		}
	}

	.socials {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: 3.5rem;

		display: flex;
		align-items: center;
		gap: 1.5rem;

		@include sm {
			margin-top: 2.5rem;
		}

		li {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 4.5rem;
			height: 4.5rem;
			background: white;
			border-radius: 0.9rem;

			@include sm {
				width: 3rem;
				height: 3rem;
				border-radius: 0.4rem;
			}

			a {
				padding: 1rem;

				@include sm {
					padding: 0.6rem;
				}
			}

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.form {
		position: relative;
		margin-right: 8rem;
		max-width: 36rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		background: #ebf2ff;
		box-shadow: 0 3.4rem 7.4rem rgba(0, 0, 0, 0.1);
		border-radius: 1.5rem;
		padding: 2.5rem;
		padding-top: 3.5rem;

		color: var(--primary-color);

		@include sm {
			margin-left: 0;
			margin-top: 7rem;
		}

		svg {
			position: absolute;
			bottom: 100%;
			right: 50%;
			transform: translateX(50%);
			margin-bottom: 2rem;
		}

		h3 {
			margin: 0;

			font-weight: 700;
			font-size: 2.5rem;
			line-height: 110%;
		}

		button {
			margin-top: 1rem;
		}

		&Agreement {
			margin: 0;

			font-size: 1.4rem;
			color: rgba(10, 38, 83, 0.64);
			font-weight: 400;
			line-height: 140%;
			text-align: center;

			a {
				display: inline;
				color: #4587e8;
			}
		}
	}
}
